<template>
  <div class="home flex flex-col items-center justify-center h-screen">
    <div class="text-white flex flex-col">
      <h1 class="text-3xl mb-4">Select your game</h1>
      <div class="flex flex-col">
        <router-link to="/domna">
          <div
            class="flex flex-row justify-center items-center bg-[#EFEDF1] max-w-[180px] h-16 m-auto py-1 rounded mb-4"
          >
            <img src="../assets/domino.svg" alt="" />
            <p class="text-2xl text-[#4259A9] font-bold">Dominoes</p>
          </div></router-link
        >
        <div>
          <div
            class="flex flex-row justify-center items-center bg-[#EFEDF1] max-w-[180px] h-16 m-auto px-6 py-1 rounded blur-[2px]"
          >
            <img src="../assets/cards.svg" alt="" class="" />
            <p class="text-2xl text-[#4259A9] font-bold">Cards</p>
          </div>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
};
</script>
