<template>
  <div class="h-screen flex flex-col items-center justify-center text-white">
    <img class="max-w-xs" src="../../public/images/win.gif" alt="" />
    <h1 class="text-2xl">Congratulations!</h1>
    <h2 class="text-4xl mt-2 border-b-2 border-indigo-500">
      {{ $route.query.winner }}
    </h2>
    <router-link to="/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-12 h-12 mt-12 bg-white text-black fill-indigo-400 rounded"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
        />
      </svg>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "WinnerPage",
};
</script>
