<template>
  <div class="cards text-white text-3xl max-w-xs mx-auto pt-10">
    <div class="flex flex-col justify-between items-center">
      <div class="flex flex-row justify-between items-center">
        <h2>Singles:</h2>
        <button
          @click="selectNumber(item.value, index)"
          v-for="(item, index) in singles"
          :key="item.value"
          :class="{
            'bg-green-800  border-2 ': selectedButton === index,
            ' text-black bg-white ': selectedButton !== index,
          }"
          class="mx-2 px-2 rounded"
        >
          {{ item.value }}
        </button>
      </div>
      <!-- <div class="flex flex-row justify-between items-center">
        <h2>Teams:</h2>
        <button
          @click="selectNumber(2, singles.length)"
          :class="{
            'text-indigo-600 bg-white px-2 rounded':
              selectedButton === singles.length,
          }"
        >
          2vs2
        </button>
      </div> -->
      <div class="mt-10">
        <h2>Team Names</h2>
        <div class="flex flex-col text-black">
          <div v-for="index in selectedNumber" :key="index" class="">
            <div class="mb-10 flex flex-col items-start">
              <p
                class="text-white text-3xl bg-indigo-600 rounded-t-xl px-7 -mb-0.5 z-40"
              >
                {{ index }}
              </p>
              <input
                class="mb-2 p-2 max-w-xs rounded"
                v-model="teamNames[index - 1]"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Score to win</h2>
        <input
          class="mb-2 p-2 max-w-xs text-black rounded"
          type="text"
          v-model="scoreToWin"
        />
      </div>
    </div>
    <router-link
      :to="{
        name: 'StartPage',
        query: {
          teamNames: teamNames,
          scoreToWin: scoreToWin,
        },
      }"
    >
      <button class="bg-green-800 px-2 py-4 rounded mt-8" @click="startGame">
        Start
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Cards",
  components: {},
  data() {
    return {
      singles: [{ value: 2 }, { value: 3 }],
      selectedNumber: 2,
      teamNames: ["Team 1", "Team 2"],
      scoreToWin: 151,
      selectedButton: 0, // Initialize with index of "2" button
    };
  },
  methods: {
    selectNumber(number, index) {
      this.selectedNumber = number;
      this.selectedButton = index;

      if (number === 3) {
        this.teamNames = ["Team 1", "Team 2", "Team 3"];
      } else {
        this.teamNames = ["Team 1", "Team 2"];
      }
    },
  },
};
</script>
