import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Domna from "../views/domna.vue";
import StartPage from "../components/start.vue";
import WinnerPage from "../views/winner.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/domna",
    name: "Domna",
    component: Domna,
  },
  {
    path: "/start",
    name: "StartPage",
    component: StartPage,
    props: true,
  },
  { path: "/winner", name: "WinnerPage", component: WinnerPage, props: true },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
