<template>
  <div class="bg-[#1E1E1E]">
    <div class="flex flex-row justify-between p-4 max-w-md m-auto">
      <router-link to="/">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-10 h-10 bg-white text-black fill-indigo-400 rounded">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
        </svg>
      </router-link>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-10 h-10 fill-red-500 bg-white rounded" @click="toggleDeleteButtonFun">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
      </svg>
    </div>
    <div :style="`grid-template-columns: repeat(${$route.query.teamNames.length}, minmax(0, 1fr))`"
      class="grid grid-rows-[auto] divide-x-2 divide-white max-w-md justify-center m-auto min-h-screen">
      <div class="mt-4 flex flex-col relative border-t-2 h-3/4" v-for="(teamName, index) in $route.query.teamNames"
        :key="index">
        <div class="border-b-2 w-full">
          <h3 class="text-white py-2 text-3xl">
            {{ teamName }} {{ $route.query.teams }}
          </h3>
          <h3 class="my-2 rounded py-2 mx-4 bg-gray-700 text-white border">
            {{ calculateTotalScore(index) }}
          </h3>
        </div>
          <div class="flex-grow">
            <div v-for="score in teamScores[index]" class="text-white mt-4 flex flex-row items-center w-1/2 mx-auto" :class="{
              'justify-between': toggleDeleteButton,
              'justify-center': !toggleDeleteButton,
            }">
              <p class="">
                {{ score }}
              </p>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-8 h-8 fill-red-500 text-white animate-bounce"
                @click="removeRound(index, round)" v-if="toggleDeleteButton">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </div>
          <div class="flex flex-col items-center mb-10 ">
            <input @keyup.enter="recordScore(index)" class="m-2 mb-6 h-7 text-center rounded w-3/4"
              v-model="currentScore[index]" type="number" :placeholder="`score`" required />
    
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-8 h-8 bg-green-900 rounded-full text-white" @click="recordScore(index)">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
      
          </div>

      </div>
    </div>

    <!-- Display winner information and provide a link to the WinnerPage -->
    <div v-if="winner">
      <h2>Game Over! Winner: {{ winner }}</h2>
      <router-link :to="{ name: 'WinnerPage', query: { winner: winner } }">
        View Winner Details
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartPage",
  data() {
    return {
      teamScores: [],
      currentScore: [],
      scoreToWin: 0, // Initialize score to win
      winner: null, // Store the winner team name
      toggleDeleteButton: false,
    };
  },
  created() {
    this.scoreToWin = Number(this.$route.query.scoreToWin); // Get score to win from query parameters
    document.addEventListener(
      "touchmove",
      function (event) {
        event.preventDefault();
      },
      { passive: true }
    );
  },
  methods: {
    recordScore(index) {
      const score = Number(this.currentScore[index]);
      if (isNaN(score) || score <= 0) {
        return;
      } else {
        // Initialize teamScores for all teams if necessary
        if (this.teamScores.length !== this.$route.query.teamNames.length) {
          this.teamScores = this.$route.query.teamNames.map(() => []);
        }

        // Set score to 0 for other teams
        for (let i = 0; i < this.teamScores.length; i++) {
          if (i !== index) {
            if (this.teamScores[i].length === this.teamScores[index].length) {
              this.teamScores[i].push(0);
            }
          }
        }

        this.teamScores[index].push(score);
        this.currentScore[index] = "";
        if (this.calculateTotalScore(index) >= this.scoreToWin) {
          // Game ends, declare the winner
          this.winner = this.$route.query.teamNames[index];
          this.redirectToWinnerPage(); // Call the method to redirect to the winner page
        }
      }
    },

    redirectToWinnerPage() {
      const winnerTeam = this.winner;
      this.$router.push({ name: "WinnerPage", query: { winner: winnerTeam } });
    },

    removeRound(index, round) {
      for (let i = 0; i < this.teamScores.length; i++) {
        this.teamScores[i].splice(round, 1);
      }
      this.toggleDeleteButton = !this.toggleDeleteButton;
    },

    calculateTotalScore(index) {
      const scores = this.teamScores[index] || [];
      return scores.reduce((total, score) => total + score, 0);
    },
    toggleDeleteButtonFun() {
      this.toggleDeleteButton = !this.toggleDeleteButton;
    },
  },
};
</script>
